<template>
    <div>
        <Html class="h-full">
            <Head>
                <Title>WeTix - The all-in-one event ticketing platform.</Title>
            </Head>
        </Html>
        <Body class="h-full"></Body>
        <slot />
    </div>
</template>

